/**
物料设置
 */
 import * as API from '@/api/index'

 export default {
     // 库存设置详情
     StockConfigAll: () => {
         return API.POST('api/stockConfig/all', )
     },
     // 库存设置
     StockConfigCreate: params => {
         return API.POST('api/stockConfig/create', params)
     },
 }